import { atom } from "jotai";

const authContextState: {
  businessUnits: ApiContract.BusinessUnit[];
  membership?: ApiContract.Membership;
  isAdmin?: boolean;
} = { businessUnits: [] };
/**
 * Stores in scope business units from authenticated session
 */
export const authContextStateAtom = atom(authContextState);
